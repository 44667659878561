.container {
  width: 100vw;
  min-height: 100vh;
  background: var(--r-blue-default, #7084ff);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro", "Segoe UI", Roboto,
    Lato, sans-serif;

  padding: 40px 0;
}

.box {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 16px;
  background: var(--r-neutral-bg1, #fff);
  box-shadow: 0px 40px 80px 0px rgba(43, 57, 143, 0.4);
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.logo {
  width: 24px;
  height: 24px;
}
.logo.done {
  width: 60px;
  height: 60px;
  margin-top: 120px;
}

.title {
  color: var(--r-neutral-title1, #192945);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.title2 {
  margin-top: 24px;
  color: var(--r-neutral-title1, #192945);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.desc {
  margin-top: 12px;
  color: var(--r-neutral-foot, #6a7587);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 21px */
}

.divider {
  width: 100%;
  height: 0.5px;
  background: var(--r-neutral-line, #e0e5ec);
  margin: 24px 0;
}

.sub {
  margin-bottom: 16px;
  color: var(--r-neutral-title1, #192945);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textarea {
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  min-height: 160px;
  max-height: 160px;
  padding: 12px;
  color: var(--r-neutral-title1, #192945);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  outline: none;
  border: 1px solid var(--r-neutral-line, #e0e5ec);
  display: block;
}

.textarea:not(.err):hover,
.textarea:not(.err):focus {
  border: 1px solid var(--r-blue-default, #7084ff);
}

.textarea.err {
  border: 1px solid var(--r-red-default, #e34935);
}

.textarea::placeholder {
  color: var(--r-neutral-foot, #6a7587);
  font-weight: 400;
  font-size: 15px;
}

.inputBox {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
}

.error {
  position: absolute;
  left: 0;
  bottom: -24px;
  color: var(--r-red-default, #e34935);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.submit,
.skip {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 18px 0px;
}

.submit:hover {
  /* box-shadow: 0 8px 24px #3a4aa54d; */
  box-shadow: 0px 8px 16px rgba(134, 151, 255, 0.3);
}
.skip:hover {
  box-shadow: 0px 8px 16px rgba(224, 229, 236, 0.3);
}

.submit {
  border-radius: 8px;
  background: var(--r-blue-default, #7084ff);
  color: var(--r-neutral-title2, #fff);
}
.skip {
  margin-top: 16px;
  border-radius: 8px;
  background: var(--r-neutral-line, #e0e5ec);
  color: var(--r-neutral-body, #3e495e);
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
  margin-bottom: 168px;
}

.link {
  padding: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.link:hover {
  border-radius: 8px;
  background: var(--r-neutral-card2, #f2f4f7);
}

.item {
  width: 40px;
  height: 40px;
}

.installBtnContainer {
  position: fixed;
  top: 40px;
  right: 40px;
}

.installBtn {
  display: inline-flex;
  padding: 14px 40px;
  justify-content: center;
  align-items: center;

  gap: 8px;
  border-radius: 16px;
  background-color: #fff;

  color: var(--r-neutral-title1, #192945);
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  text-decoration: none;
}

.installBtn:hover {
  border-radius: 16px;
  background: var(--r-neutral-bg1, #fff);
  box-shadow: 0px 4px 16px 0px rgba(33, 48, 138, 0.32);
}

.installBtn > img {
  width: 28px;
  height: 28px;
}

.footer {
  background-color: transparent;
  text-align: center;
  margin-top: 32px;
  color: var(--r-neutral-foot, #6a7587);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

.email {
  color: var(--r-blue-default, #7084ff);
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
}
